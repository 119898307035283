var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"550px","persistent":""},model:{value:(_vm.toogleNewModal),callback:function ($$v) {_vm.toogleNewModal=$$v},expression:"toogleNewModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.titles.new", [ _vm.$vuetify.lang.t("$vuetify.menu.beneficiary") ])))])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"my-10",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.firstName'),"rules":_vm.formRule.firstName,"required":""},model:{value:(_vm.newBeneficiary.first_name),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "first_name", $$v)},expression:"newBeneficiary.first_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.lastName'),"required":""},model:{value:(_vm.newBeneficiary.last_name),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "last_name", $$v)},expression:"newBeneficiary.last_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.email'),"rules":_vm.formRule.email,"autocomplete":"off","required":""},model:{value:(_vm.newBeneficiary.email),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "email", $$v)},expression:"newBeneficiary.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('vue-tel-input-vuetify',_vm._b({attrs:{"placeholder":_vm.$vuetify.lang.t('$vuetify.phone_holder'),"label":_vm.$vuetify.lang.t('$vuetify.phone'),"required":"","rules":_vm.formRule.phone,"select-label":_vm.$vuetify.lang.t('$vuetify.country'),"error-messages":_vm.errorPhone,"prefix":_vm.countrySelect
                  ? `+` + _vm.countrySelect.dialCode
                  : ``},on:{"country-changed":_vm.onCountry,"keypress":_vm.numbers,"input":_vm.onInput},scopedSlots:_vm._u([{key:"message",fn:function({ key, message }){return [_vm._t("label",null,null,{ key, message }),_vm._v(" "+_vm._s(message)+" ")]}}],null,true),model:{value:(_vm.newBeneficiary.phone),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "phone", $$v)},expression:"newBeneficiary.phone"}},'vue-tel-input-vuetify',_vm.bindProps,false))],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.province')},model:{value:(_vm.newBeneficiary.province),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "province", $$v)},expression:"newBeneficiary.province"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.city')},model:{value:(_vm.newBeneficiary.city),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "city", $$v)},expression:"newBeneficiary.city"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.plan.benefit_limit'),"required":"","properties":{
                            clearable: false
                          },"options":{
                            length: 15,
                            precision: 0,
                            empty: 0,
                          }},model:{value:(_vm.newBeneficiary.limit_affiliate_beneficiary),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "limit_affiliate_beneficiary", $$v)},expression:"newBeneficiary.limit_affiliate_beneficiary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.menu.partners'),"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.menu.partners" ))+" "),_c('v-tooltip',{staticClass:"md-6",attrs:{"max-width":"350","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('p',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.messages.warning_partner_beneficiary" ))+" ")])])],1)]},proxy:true}]),model:{value:(_vm.newBeneficiary.isPartner),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "isPartner", $$v)},expression:"newBeneficiary.isPartner"}})],1),(_vm.newBeneficiary.isPartner)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.plan.referral_limit'),"required":"","properties":{
                            clearable: false
                          },"options":{
                            length: 15,
                            precision: 0,
                            empty: 0,
                          }},model:{value:(_vm.newBeneficiary.limit_affiliate),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "limit_affiliate", $$v)},expression:"newBeneficiary.limit_affiliate"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"counter":120,"maxlength":"120","rules":_vm.formRule.address,"label":_vm.$vuetify.lang.t('$vuetify.address'),"required":""},model:{value:(_vm.newBeneficiary.address),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "address", $$v)},expression:"newBeneficiary.address"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"counter":120,"maxlength":"120","label":_vm.$vuetify.lang.t(
                  '$vuetify.access.description'
                )},model:{value:(_vm.newBeneficiary.description),callback:function ($$v) {_vm.$set(_vm.newBeneficiary, "description", $$v)},expression:"newBeneficiary.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":_vm.isActionInProgress},on:{"click":function($event){return _vm.toogleNewModal(false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.actions.cancel"))+" ")],1),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.formValid || _vm.isActionInProgress,"loading":_vm.isActionInProgress,"color":"primary"},on:{"click":_vm.createNewBeneficiary}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.actions.save"))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }