<template>
  <v-container>
    <v-dialog v-model="showDialogCountry"
              max-width="450">
      <v-card>
        <v-card-text>
          <select-country
            :model="countrySelect"
            :hint="$vuetify.lang.t('$vuetify.hints.select_country')"
            :select-label="$vuetify.lang.t('$vuetify.plan.country')"
            :multiple="false"
            @updateData="updateCountry"
            @emptyModel="emptyCountry=true"
            @noEmptyModel="emptyCountry=false"
          />
        </v-card-text>
        <v-card-actions>

          <v-btn
            class="mb-2"
            @click="showDialogCountry=false"
          >
            <v-icon>mdi-close</v-icon>
            {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
          </v-btn>
          <v-spacer/>
          <v-btn
            :disabled="emptyCountry"
            class="mb-2"
            color="primary"
            @click="saveCountry"
          >
            <v-icon>mdi-content-save</v-icon>
            {{ $vuetify.lang.t("$vuetify.actions.save") }}
          </v-btn>
        </v-card-actions></v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="plan.countries"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.plan.country') }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn @click="showDialogCountry=true">
            {{$vuetify.lang.t('$vuetify.actions.add_one')}}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.basic_limit`]="{ item }">
        <v-edit-dialog
          v-if="plan.type === 'basic'"
          style="width: 400px"
          :return-value.sync="item.basic_limit"
          large
          persistent
          :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
          :save-text="$vuetify.lang.t('$vuetify.actions.save')"
        >
          <div :style="(parseFloat(item.basic_limit).toFixed(2) === '0.00' && plan.type === 'basic') ?'color: red': ''">
            {{ parseFloat(item.basic_limit).toFixed(2) + ' ' + item.country.currency }}
            <v-tooltip
              max-width="150"
              bottom
              class="md-6"
              v-if="parseFloat(item.basic_limit).toFixed(2) === '0.00'  && plan.type === 'basic'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{
                  $vuetify.lang.t('$vuetify.hints.online_basic_limit_zero')
                }}</span>
            </v-tooltip>
          </div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $vuetify.lang.t("$vuetify.actions.edit") }}
            </div>
            <v-text-field-money
              v-model="item.basic_limit"
              :label="$vuetify.lang.t('$vuetify.actions.edit')"
              required
              :options="{
                            length: 15,
                            precision: 2,
                            empty: 0.0
                          }"
              :properties="{
                            prefix: item.country.currency,
                            clearable: false
                          }"
            />
          </template>
        </v-edit-dialog>

        <div v-else>
          {{ parseFloat(item.basic_limit).toFixed(2) + ' ' + item.country.currency }}
        </div>
      </template>
      <template v-slot:[`item.cant_box`]="{ item }">
        <v-edit-dialog
          v-if="plan.type === 'basic'"
          style="width: 400px"
          :return-value.sync="item.cant_box"
          large
          persistent
          :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
          :save-text="$vuetify.lang.t('$vuetify.actions.save')"
        >
          <div :style="(parseFloat(item.cant_box).toFixed(2) === '0.00' && plan.type === 'basic') ?'color: red': ''">
            {{ parseFloat(item.cant_box).toFixed(2) }}
            <v-tooltip
              max-width="150"
              bottom
              class="md-6"
              v-if="parseFloat(item.cant_box).toFixed(2) === '0.00'  && plan.type === 'basic'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{
                  $vuetify.lang.t('$vuetify.hints.cant_box_zero')
                }}</span>
            </v-tooltip>
          </div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $vuetify.lang.t("$vuetify.actions.edit") }}
            </div>
            <v-text-field-money
              v-model="item.cant_box"
              :label="$vuetify.lang.t('$vuetify.actions.edit')"
              required
              :options="{
                            length: 15,
                            precision: 2,
                            empty: 0.0
                          }"
              :properties="{
                            clearable: false
                          }"
            />
          </template>
        </v-edit-dialog>

        <div v-else>
          {{ parseFloat(item.cant_box).toFixed(2) }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: 'PlanCountriesTable',
  props: {
    plan: {
      type: Object,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      emptyCountry: false,
      countrySelect: null,
      showDialogCountry: false
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.country'),
          value: 'country.name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.money_limit'),
          value: 'basic_limit'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.boxes'),
          value: 'cant_box'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    showDialogCountry () {
      if (this.showDialogCountry === false) {
        this.countrySelect = null
      }
    }
  },
  methods: {
    updateCountry (countries) {
      this.countrySelect = countries
    },
    saveCountry () {
      this.plan.countries.push({ country: this.countrySelect, basic_limit: 0.00, cant_box: 0.00 })
      this.showDialogCountry = false
      this.countrySelect = null
    },
    deleteItem (item) {
      const editedIndex = this.plan.countries.indexOf(item)
      this.plan.countries.splice(this.editedIndex, 1)
    }
  }
}
</script>

<style scoped>

</style>
